import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ParameterResultCard from '../../../components/ParameterResultCard';
import LabResultsAccordion from '../LabResults/LabResultsAccordion';

import './index.scss';

const LabResults = ({ labResults, handleOpenModal }) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(0);

  return (
    <>
      {labResults.map((test, index) => {
        const { isParametersGroup } = test;
        if (isParametersGroup) {
          return (
            <LabResultsAccordion
              key={test.name}
              name={test.name}
              description={test.description}
              info={test.info}
              handleInfo={() => handleOpenModal(test.info)}
              isFirstAccordionOpen={index === openAccordionIndex}
              onToggle={() => setOpenAccordionIndex(index)}
              content={test.parameters.map(parameter => (
                <ParameterResultCard
                  className='lab-results__result-card'
                  key={parameter.name}
                  resultBackgroundColor={parameter.color}
                  title={parameter.name}
                  text={parameter.description}
                  valueType={parameter.valueType}
                  stringValue={parameter.value}
                  numberValue={parameter.value}
                  previousResults={false}
                  handleInfo={() => handleOpenModal(parameter.info)}
                  info={parameter.info}
                  unit={parameter.unit}
                  additionalInfo={parameter.additionalInfo}
                />
              ))}
            />
          );
        } else {
          // In case when type name is hpvLowRisk, hpvPotentiallyHighRisk, hpvHighRisk, we want to check additionalInfo. If additional info is not null abnd stringValue is positive then we want to create text varible that will be description + additionalInfo.
          let text = test.description;
          if (
            ['hpvLowRisk', 'hpvPotentiallyHighRisk', 'hpvHighRisk'].includes(
              test.type
            ) &&
            test.additionalInfo &&
            test.interpretation === 'positive'
          ) {
            text = `${test.description} ${test.additionalInfo}.`;
          }

          // in case that test.type is vdrl I should replace {whenWasSyphilisTreated} with test.whenWasSyphilisTreated and {additionalInfo} with test.additionalInfo
          if (test.type === 'vdrl') {
            text = text
              .replace('{whenWasSyphilisTreated}', test.whenWasSyphilisTreated)
              .replace('{additionalInfo}', test.additionalInfo);
          }

          return (
            <ParameterResultCard
              key={index}
              resultBackgroundColor={test.color}
              title={test.name}
              text={text}
              valueType={test.valueType}
              stringValue={test.value}
              numberValue={test.value}
              previousResults={false}
              handleInfo={() => handleOpenModal(test.info)}
              info={test.info}
              unit={test.unit}
              additionalInfo={test.additionalInfo}
            />
          );
        }
      })}
    </>
  );
};

LabResults.propTypes = {
  labResults: PropTypes.array,
  handleOpenModal: PropTypes.func
};

export default LabResults;
