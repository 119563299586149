import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Checkbox from '../Checkbox';
import cx from 'classnames';

import './index.scss';

const MAIN_SLIDER_NAME = 'slide-content__children';

const CheckboxesQuestion = ({
  data,
  setCurrentScreenAnswer,
  isRadioButton
}) => {
  const questionElement = useRef(null);
  const [answers, setAnswers] = useState(isRadioButton ? null : []);
  const { answerOptions, isSorted, text } = data;

  // If there are two CheckboxesQuestion one after another, we need to reset answers array
  useEffect(() => {
    const parentClass = questionElement.current.parentNode.classList[0];
    const isInMainSlide = parentClass === MAIN_SLIDER_NAME;

    if (isInMainSlide && data.answerValue && data.answerValue.value) {
      setAnswers(data.answerValue.value);
      setCurrentScreenAnswer(data.answerValue.value);
    } else {
      if (!isRadioButton) {
        setAnswers([]);
      } else {
        setAnswers(null);
      }
    }
  }, [data, isRadioButton, setCurrentScreenAnswer]);

  const handleCheckboxClick = event => {
    let currentAnswers = null;
    const value = event.target.value;
    if (!isRadioButton) {
      currentAnswers = [];
      if (value === 'none') {
        if (!answers.includes(value)) {
          currentAnswers.push(value);
        }
      } else {
        if (!answers.includes(value)) {
          currentAnswers.push(...answers);
          currentAnswers.push(value);
        } else {
          currentAnswers = answers.filter(answer => {
            return answer !== value;
          });
        }
      }
    } else {
      currentAnswers = value;
    }
    setAnswers(currentAnswers);
    setCurrentScreenAnswer(currentAnswers.length > 0 ? currentAnswers : null);
  };

  const getSortedOptions = options => {
    const noneOption = options.find(option => option.key === 'none');
    let sortedOptions = [];
    const optionsWithoutNone = options.filter(option => option.key !== 'none');
    optionsWithoutNone.sort((a, b) => a.label.localeCompare(b.label));
    sortedOptions = optionsWithoutNone;
    if (noneOption) {
      sortedOptions.push(noneOption);
    }
    return sortedOptions;
  };

  const options = isSorted ? getSortedOptions(answerOptions) : answerOptions;
  return (
    <div className='checkboxes-question' ref={questionElement}>
      <div className='checkboxes-question__content'>
        {text && (
          <div className='checkboxes-question__description-wrapper'>
            <Markdown
              className='checkboxes-question__text'
              rehypePlugins={[rehypeRaw]}
            >
              {text}
            </Markdown>
          </div>
        )}
        <div className='checkboxes-question__options'>
          {options.map(answer => {
            const isNone = answer.key === 'none';
            const checkboxClasses = cx('checkboxes-question__checkbox', {
              'checkboxes-question__checkbox--none': isNone
            });
            return (
              <Checkbox
                isRadioButton={isRadioButton}
                className={checkboxClasses}
                key={answer.key}
                value={answer.key}
                label={answer.label}
                checked={
                  !isRadioButton
                    ? answers && answers.includes(answer.key)
                    : answers && answers === answer.key
                }
                onChange={handleCheckboxClick}
                disabled={
                  !isRadioButton
                    ? answers &&
                      answers.includes('none') &&
                      answer.key !== 'none'
                    : false
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

CheckboxesQuestion.propTypes = {
  data: PropTypes.object,
  setCurrentScreenAnswer: PropTypes.func,
  // Then we have only one answer
  isRadioButton: PropTypes.bool
};

export default CheckboxesQuestion;
